import { useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import RolesDropdown from "./RolesDropdown";
import activeOrganisationAtom from "../../recoil/auth0permissions.ts/activeOrganisationAtom";
import { useInviteUserMutation } from "../../services/coc00nApi/user";
import { useGetRolesQuery } from "../../services/coc00nApi/roles";
import LoadingOverlay from "../global/LoadingOverlay";

interface InviteUserFormData {
  coc00nId?: string;
  userName: string;
  userEmail: string;
  userRoles: string[];
}

interface CreateInviteUserProps {
  onCancel?: () => void;
}

export default function InviteUser(
  { onCancel }: CreateInviteUserProps = { onCancel: undefined },
) {
  const activeOrganisationId = useRecoilValue(activeOrganisationAtom);

  const { data: rolesResponse } = useGetRolesQuery();

  const availableRoles = useMemo<
    { id: string; name: string; description: string }[]
  >(() => rolesResponse?.roles || [], [rolesResponse]);

  // This will be removed when api is plugged in, as we can use loading states from react query, just for testing
  const [isLoading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<InviteUserFormData>({
    defaultValues: {
      userName: "",
      userEmail: "",
      userRoles: [],
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const { mutateAsync: inviteUser } = useInviteUserMutation();

  const onSubmit = async (data: InviteUserFormData) => {
    console.log("Inviting user to Coc00n! ", data);

    setLoading(true);

    const inviteUserParams = {
      coc00n: activeOrganisationId,
      body: {
        email: data.userEmail,
        roles: data.userRoles,
      },
    };

    await inviteUser(inviteUserParams);

    setLoading(false);

    reset();
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex flex-column justify-content-center p-2"
    >
      {isLoading && <LoadingOverlay />}
      <h4 className="text-center">Invite new user to Coc00n</h4>
      {/* <Form.Group controlId="userName">
        <Form.Label>User Name</Form.Label>
        <Controller
          name="userName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Form.Control type="text" {...field} />}
        />
      </Form.Group> */}
      <Form.Group controlId="userEmail">
        <Form.Label>User Email</Form.Label>
        <Controller
          name="userEmail"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Form.Control type="email" {...field} />}
        />
      </Form.Group>
      <Form.Group controlId="userRoles">
        <Form.Label>User Roles</Form.Label>
        <Controller
          name="userRoles"
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <RolesDropdown
              availableRoles={availableRoles}
              assignedRoles={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Form.Group>
      <div className="d-flex flex-column gap-1 my-2">
        <Button type="submit" variant="primary" className="rounded">
          Submit
        </Button>
        {onCancel && (
          <Button onClick={onCancel} variant="secondary" className="rounded">
            Cancel
          </Button>
        )}
      </div>
    </Form>
  );
}
