import { useMutation } from "@tanstack/react-query";
import { coc00nApi } from "./api";

// eslint-disable-next-line import/prefer-default-export
export function useInviteUserMutation() {
  const inviteUser = (params: {
    coc00n: string;
    body: {
      email: string;
      roles: string[];
    };
  }) => coc00nApi.post(`/coc00n/${params.coc00n}/member`, { ...params.body });

  const mutation = useMutation({
    mutationFn: inviteUser,
  });

  return mutation;
}
