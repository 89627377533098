/* eslint-disable import/prefer-default-export */
import { useQuery } from "@tanstack/react-query";
import { coc00nApi } from "./api";

export function useGetRolesQuery() {
  const getRoles = () => coc00nApi.get("/roles").then((res) => res.data);

  const query = useQuery<{
    roles: { id: string; name: string; description: string }[];
  }>({
    queryKey: ["roles"],
    queryFn: getRoles,
  });

  return query;
}
