import { Badge } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { useTestApiQuery } from "../../services/coc00nApi";
import networkErrorAtom from "../../recoil/auth0permissions.ts/networkErrorAtom";

function StatusIndicator() {
  useTestApiQuery({ foo: "bar" });

  const networkError = useRecoilValue(networkErrorAtom);

  if (!networkError) {
    return (
      <Badge bg="success" pill className="my-auto">
        Online
      </Badge>
    );
  }

  return (
    <Badge bg="danger" pill className="my-auto">
      Offline
    </Badge>
  );
}

export default StatusIndicator;
