import { Auth0Permission, PermissionsAction } from "../types/permissions";

/*
  {
    thingUserCanDo : [listOfPermissionsThatAllowThisAction]
  }
*/

// Define the array of permissions
export const permissionsList = [
  "read:UserBasic_all",
  "read:UserBasic_coc00n",
  "read:UserConfig_all",
  "read:UserConfig_coc00n",
  "read:UserConfig_self",
  "read:DeviceBasic_all",
  "read:DeviceBasic_coc00n",
  "read:DeviceBasic_self",
  "read:DeviceTechDetails_all",
  "read:Coc00nBasic_all",
  "read:Coc00nBasic_coc00n",
  "read:Coc00nLimits_all",
  "read:Coc00nLimits_coc00n",
  "read:Coc00nTechdetails_all",
  "read:Coc00nBillingdetails_all",
  "read:Coc00nBillingDetails_coc00n",
  "read:PartnerBasic_all",
  "read:PartnerBasic_coc00n",
  "read:PartnerCommercial_all",
  "read:PartnerCommercial_coc00n",
  "read:Coc00nService_all",
  "read:Coc00nService_coc00n",
  "read:Service_all",
  "read:KbArticle_all",
  "read:KbArticleVersion_all",
  "read:KbTag_all",
  "read:KbCategory_all",
  "read:Request_all",
  "read:Request_coc00n",
  "read:DeviceType_all",
  "create:UserBasic_all",
  "create:UserBasic_coc00n",
  "create:UserConfig_all",
  "create:UserConfig_coc00n",
  "create:DeviceBasic_all",
  "create:DeviceBasic_coc00n",
  "create:DeviceBasic_self",
  "create:DeviceTechDetails_all",
  "create:Coc00nBasic_all",
  "create:Coc00nLimits_all",
  "create:Coc00nTechdetails_all",
  "create:Coc00nBillingdetails_all",
  "create:PartnerBasic_all",
  "create:PartnerCommercial_all",
  "create:Coc00nService_all",
  "create:Service_all",
  "create:KbArticle_all",
  "create:KbArticleVersion_all",
  "create:KbTag_all",
  "create:KbCategory_all",
  "create:Request_all",
  "create:Request_coc00n",
  "create:DeviceType_all",
  "update:UserBasic_all",
  "update:UserBasic_coc00n",
  "update:UserConfig_all",
  "update:UserConfig_coc00n",
  "update:DeviceBasic_all",
  "update:DeviceBasic_coc00n",
  "update:DeviceBasic_self",
  "update:DeviceTechDetails_all",
  "update:Coc00nBasic_all",
  "update:Coc00nBasic_coc00n",
  "update:Coc00nLimits_all",
  "update:Coc00nTechdetails_all",
  "update:Coc00nBillingdetails_all",
  "update:Coc00nBillingDetails_coc00n",
  "update:PartnerBasic_all",
  "update:PartnerBasic_coc00n",
  "update:PartnerCommercial_all",
  "update:Coc00nService_all",
  "update:Service_all",
  "update:KbArticle_all",
  "update:KbArticleVersion_all",
  "update:KbTag_all",
  "update:KbCategory_all",
  "update:Request_all",
  "update:Request_coc00n",
  "update:DeviceType_all",
  "delete:UserBasic_all",
  "delete:UserBasic_coc00n",
  "delete:UserConfig_all",
  "delete:UserConfig_coc00n",
  "delete:DeviceBasic_all",
  "delete:DeviceBasic_coc00n",
  "delete:DeviceBasic_self",
  "delete:DeviceTechDetails_all",
  "delete:DeviceTechdetails_coc00n",
  "delete:DeviceTechdetails_self",
  "delete:Coc00nBasic_all",
  "delete:Coc00nLimits_all",
  "delete:Coc00nTechdetails_all",
  "delete:Coc00nBillingdetails_all",
  "delete:PartnerBasic_all",
  "delete:PartnerCommercial_all",
  "delete:Coc00nService_all",
  "delete:Service_all",
  "delete:KbArticle_all",
  "delete:KbArticleVersion_all",
  "delete:KbTag_all",
  "delete:KbCategory_all",
  "delete:Request_all",
  "delete:Request_coc00n",
  "delete:DeviceType_all",
];

const coc00nAdminToolsActionsMap: Partial<
  Record<PermissionsAction, Auth0Permission[]>
> = {
  createCoc00n: ["create:Coc00nBasic_all"],
  updateCoc00n: ["update:Coc00nBasic_all"],
};

const standardActionsMap: Partial<
  Record<PermissionsAction, Auth0Permission[]>
> = {};

export const permissionsMap: Partial<
  Record<PermissionsAction, Auth0Permission[]>
> = {
  ...coc00nAdminToolsActionsMap,
  ...standardActionsMap,
};

// If any admin tools available,
const viewAdminTools = {
  viewAdminTools: [...new Set(Object.values(permissionsMap))].flat(),
};

Object.assign(permissionsMap, viewAdminTools);
