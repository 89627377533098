import axios, { InternalAxiosRequestConfig } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { set } from "lodash";
import { stringify } from "qs";
import { useSetRecoilState } from "recoil";
import { coc00nAPIURL } from "../../utils/config";
import networkErrorAtom from "../../recoil/auth0permissions.ts/networkErrorAtom";

interface QueryParams {
  [key: string]: string | number | boolean | (string | number | boolean)[];
}

export const coc00nApi = axios.create({
  baseURL: coc00nAPIURL,
});

// Attach Auth0 token and format request query params / body properly
export function useAxiosAuthMiddleware() {
  // Attach access token
  const { getAccessTokenSilently } = useAuth0();

  coc00nApi.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      try {
        const token = await getAccessTokenSilently();

        if (token) {
          set(config.headers, "Authorization", `Bearer ${token}`);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error getting token");
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // Format query params / body
  coc00nApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (
      config.method === "get" &&
      config.params &&
      typeof config.params === "object"
    ) {
      // Assemble params into query string
      set(config, "paramsSerializer", (params: QueryParams) =>
        stringify(params, { arrayFormat: "brackets" }),
      );
    } else if (
      ["post", "put", "patch"].includes(config.method || "") &&
      config.data
    ) {
      set(config.headers, "Content-Type", "application/json");
    }

    return config;
  });
}

// Check for network error or timeout to set state of recoil atom
export function useAxiosNetworkStateMiddleware() {
  const setNetworkError = useSetRecoilState(networkErrorAtom);

  coc00nApi.interceptors.response.use(
    (response) => {
      // Reset network error state on successful response
      setNetworkError(false);
      return response;
    },
    (error) => {
      console.log("Error!");

      if (!error.response || error.code === "ECONNABORTED") {
        // Network error or timeout error
        console.log("Network error!");

        setNetworkError(true);

        return Promise.reject(
          new Error("Network timeout, please try again later."),
        );
      }

      // return {error: 'No network connection'}

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ error: "No network connection" });
    },
  );
}
