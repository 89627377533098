import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { coc00nApi } from "./api";
import { Auth0Permission } from "../../types/permissions";

// eslint-disable-next-line import/prefer-default-export
export function useTestApiQuery(
  queryParams?: Record<
    string,
    string | number | boolean | (string | number | boolean)[]
  >,
) {
  const [refetchInterval, setRefetchInterval] = useState(60000);

  const queryFn = () =>
    coc00nApi.get("/test", {
      timeout: 5000,
      params: queryParams,
    });

  const query = useQuery({
    queryKey: ["testApi", queryParams],
    queryFn,
    refetchInterval,
    retry: 0,
    refetchOnReconnect: true,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (query.isSuccess) {
      setRefetchInterval(60000);
    } else if (query.isError) {
      setRefetchInterval(15000);
      console.log(query.error);
    }
  }, [query.isError, query.isSuccess, query.error]);

  return query;
}

export function useTestTokenQuery() {
  const queryFn = () =>
    coc00nApi.get("/test-token", {
      timeout: 5000,
    });

  return useQuery({
    queryKey: ["testToken"],
    queryFn,
  });
}

export function useTestPermissionsQuery(permissions: Auth0Permission[]) {
  const queryFn = () =>
    coc00nApi.get("/test-permissions", {
      params: {
        permissions,
      },
    });

  return useQuery({
    queryKey: ["testPermissions", permissions],
    queryFn,
    enabled: false,
    retry: false,
  });
}

export function useTestBodyQuery() {
  const queryFn = () =>
    coc00nApi.post("/test-body", {
      foo: "bar",
    });

  return useQuery({
    queryKey: ["testBody"],
    queryFn,
    enabled: false,
    retry: false,
  });
}
