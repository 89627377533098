import { Spinner } from "react-bootstrap";

export default function LoadingOverlay() {
  return (
    <div
      style={{ zIndex: 999 }}
      className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-75"
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
