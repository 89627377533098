import React from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { RecoilRoot } from "recoil";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";
import "./styles/App.scss";
import { auth0Domain, auth0ClientId, coc00nAPIAudience } from "./utils/config";

const providerConfig: Auth0ProviderOptions = {
  domain: auth0Domain,
  clientId: auth0ClientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: coc00nAPIAudience,
    scope: "openid profile email offline_access",
  },
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <Auth0Provider {...providerConfig}>
        <RecoilRoot>
          <App />
          <ToastContainer
            className="pb-3 pb-md-0"
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="colored"
          />
        </RecoilRoot>
      </Auth0Provider>
    </React.StrictMode>,
  );
} else {
  console.error("No root element found");
}

const onUpdate = (registration: ServiceWorkerRegistration) => {
  const waitingServiceWorker = registration.waiting;

  if (waitingServiceWorker) {
    waitingServiceWorker.addEventListener("statechange", (event: Event) => {
      if ((event.target as ServiceWorker).state === "activated") {
        // Currently reloading the app as soon as a new version is available.
        // Ensure any updates won't cause issues.
        // if (window.confirm("There is a new version of the app ready. Please reload to update.")) {
        window.location.replace(`${window.location.origin}/`);
        // }
      }
    });
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
  }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ onUpdate });

const registerServiceWorkerOnAppFocus = () => {
  if (!document.hidden) {
    serviceWorkerRegistration.register({ immediate: true, onUpdate });
  }
};

window.document.addEventListener(
  "visibilitychange",
  registerServiceWorkerOnAppFocus,
);
