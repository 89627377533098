import { useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import auth0PermissionsAtom from "../../recoil/auth0permissions.ts/permissionsAtom";
import RolesDropdown from "./RolesDropdown";
import LoadingModal from "../global/LoadingOverlay";
import { useCreateCoc00nMutation } from "../../services/coc00nApi/coc00n";
import { CreateCoc00nFormData } from "../../types/form-input-data/CreateCoc00n";
import { InviteUserFormData } from "../../types/form-input-data/InviteUser";
import { useInviteUserMutation } from "../../services/coc00nApi/user";
import { useGetRolesQuery } from "../../services/coc00nApi/roles";

interface CreateCoc00nFormProps {
  onCancel?: () => void;
}

export default function CreateCoc00nForm(
  { onCancel }: CreateCoc00nFormProps = { onCancel: undefined },
) {
  // This will be removed when api is plugged in, as we can use loading states from react query, just for testing
  const [isLoading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<
    CreateCoc00nFormData & InviteUserFormData
  >({
    defaultValues: {
      orgName: "",
      userName: "",
      userEmail: "",
      userRoles: [],
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const { data: rolesResponse } = useGetRolesQuery();
  const { mutateAsync: createCoc00n } = useCreateCoc00nMutation();
  const { mutateAsync: inviteUser } = useInviteUserMutation();

  const onSubmit = async (data: CreateCoc00nFormData & InviteUserFormData) => {
    setLoading(true);

    const createCoc00nBody = {
      name: data.orgName,
    };

    const newCoc00n = await createCoc00n(createCoc00nBody);

    if (newCoc00n) {
      const inviteUserParams = {
        coc00n: newCoc00n.data.coc00n.auth0Id,
        body: {
          email: data.userEmail,
          roles: data.userRoles,
        },
      };

      await inviteUser(inviteUserParams);
    }

    setLoading(false);

    reset();
  };

  const availableRoles = useMemo<
    { id: string; name: string; description: string }[]
  >(() => rolesResponse?.roles || [], [rolesResponse]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex flex-column justify-content-center p-2"
    >
      {isLoading && <LoadingModal />}
      <h4 className="text-center">Create new Coc00n</h4>
      <Form.Group controlId="orgName">
        <Form.Label>Organisation Name</Form.Label>
        <Controller
          name="orgName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Form.Control type="text" {...field} />}
        />
      </Form.Group>
      {/* <Form.Group controlId="ownerName">
        <Form.Label>Owner Name</Form.Label>
        <Controller
          name="userName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Form.Control type="text" {...field} />}
        />
      </Form.Group> */}
      <Form.Group controlId="ownerEmail">
        <Form.Label>Owner Email</Form.Label>
        <Controller
          name="userEmail"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Form.Control type="email" {...field} />}
        />
      </Form.Group>
      {rolesResponse?.roles && (
        <Form.Group controlId="ownerRoles">
          <Form.Label>Owner Roles</Form.Label>
          <Controller
            name="userRoles"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <RolesDropdown
                availableRoles={availableRoles}
                assignedRoles={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Form.Group>
      )}
      <div className="d-flex flex-column gap-1 my-2">
        <Button type="submit" variant="primary" className="rounded">
          Submit
        </Button>
        {onCancel && (
          <Button onClick={onCancel} variant="secondary" className="rounded">
            Cancel
          </Button>
        )}
      </div>
    </Form>
  );
}
