import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Modal,
  Stack,
} from "react-bootstrap";
import CreateCoc00nForm from "../components/forms/CreateCoc00n";
import InviteUser from "../components/forms/InviteUser";
import SecureComponentWrapper from "../components/global/SecureComponentWrapper";

export default function Admin() {
  // Possible admin functions
  const [showCreateCoc00nModal, setShowCreateCoc00nModal] = useState(false);

  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  return (
    <Container>
      <Card>
        <CardHeader>Available Actions</CardHeader>
        <CardBody>
          <Stack gap={2}>
            <SecureComponentWrapper
              requiredPermissions={["create:UserBasic_coc00n"]}
            >
              <Modal
                show={showInviteUserModal}
                onHide={() => setShowInviteUserModal(false)}
              >
                <InviteUser onCancel={() => setShowInviteUserModal(false)} />
              </Modal>
              <Button onClick={() => setShowInviteUserModal(true)}>
                Invite member to coc00n
              </Button>
            </SecureComponentWrapper>
            <SecureComponentWrapper
              requiredPermissions={["create:Coc00nBasic_all"]}
            >
              <Modal
                show={showCreateCoc00nModal}
                onHide={() => setShowCreateCoc00nModal(false)}
              >
                <CreateCoc00nForm
                  onCancel={() => setShowCreateCoc00nModal(false)}
                />
              </Modal>
              <Button onClick={() => setShowCreateCoc00nModal(true)}>
                Create new coc00n
              </Button>
            </SecureComponentWrapper>
          </Stack>
        </CardBody>
      </Card>
    </Container>
  );
}
