import { useAuth0 } from "@auth0/auth0-react";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import auth0PermissionsAtom, {
  isPermissionLoaded,
} from "../recoil/auth0permissions.ts/permissionsAtom";
import activeOrganisationAtom from "../recoil/auth0permissions.ts/activeOrganisationAtom";

export default function useFetchUserPermissions() {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const setPermissions = useSetRecoilState(auth0PermissionsAtom);
  const setPermissionsLoaded = useSetRecoilState(isPermissionLoaded);
  const setActiveOrganisationId = useSetRecoilState(activeOrganisationAtom);

  useEffect(() => {
    async function fetchPermissions() {
      try {
        const token = await getAccessTokenSilently();

        const decodedToken = jwtDecode(token) as Auth0AccessToken;

        setPermissions(decodedToken.permissions);
        setActiveOrganisationId(decodedToken.org_id);
        setPermissionsLoaded(true);
      } catch (e) {
        console.error("Failed to fetch permissions: ", e);
        setPermissions([]);
        setPermissionsLoaded(true);
      }
    }

    if (isAuthenticated && !isLoading) {
      fetchPermissions();
    }
  }, [
    setPermissions,
    setPermissionsLoaded,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    setActiveOrganisationId,
  ]);
}
