import { Button, Container } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import auth0PermissionsAtom from "../../recoil/auth0permissions.ts/permissionsAtom";

export default function Unauthorised() {
  const permissions = useRecoilValue(auth0PermissionsAtom);

  return (
    <Container>
      Sorry, you are not authorised to view that page. If you think this is an
      error, try logging out and back in. If this issue persists, please contact
      an administrator.
      <Button onClick={() => console.log(permissions)}>Log permissions</Button>
    </Container>
  );
}
