import { useMutation } from "@tanstack/react-query";
import { coc00nApi } from "./api";
import { CreateCoc00nQueryInput } from "../../types/form-input-data/CreateCoc00n";

export function useCreateCoc00nMutation() {
  const createCoc00n = (body: CreateCoc00nQueryInput) =>
    coc00nApi.post("/coc00n", body);

  const mutation = useMutation({
    mutationFn: createCoc00n,
  });

  return mutation;
}

export function useUpdateCoc00nMutation() {
  const mutationFn = (body: any) => coc00nApi.patch("/coc00n", { body });

  const mutation = useMutation({
    mutationFn,
  });

  return mutation;
}
