import { useMemo } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Container,
  Dropdown,
} from "react-bootstrap";
import { X } from "react-bootstrap-icons";

export default function RolesDropdown({
  availableRoles,
  assignedRoles,
  onChange,
}: {
  availableRoles: { id: string; name: string; description: string }[];
  assignedRoles: string[];
  onChange: (roleArr: string[]) => void;
}) {
  const handleAddRole = (roleToAdd: string) => {
    onChange([roleToAdd, ...assignedRoles]);
  };

  const handleRemoveRole = (roleToRemove: string) => {
    onChange(assignedRoles.filter((role) => role !== roleToRemove));
  };

  const unassignedRoles = useMemo(
    () =>
      availableRoles.filter(
        (roleConfig) => !assignedRoles.includes(roleConfig.name),
      ),
    [availableRoles, assignedRoles],
  );

  return (
    <>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle variant="outline" disabled={!unassignedRoles.length}>
          Assign Roles
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {unassignedRoles.map((roleConfig) => (
            <Dropdown.Item
              key={roleConfig.id}
              onClick={() => handleAddRole(roleConfig.name)}
              active={assignedRoles.includes(roleConfig.name)}
            >
              {roleConfig.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Container className="d-flex flex-wrap mt-1 p-0 gap-2">
        {assignedRoles.map((role) => (
          <Badge
            key={role}
            bg="secondary"
            className="rounded py-1 px-2 d-flex align-items-center"
          >
            {role}
            <Button
              variant="secondary"
              className="p-0 m-0 ms-1"
              onClick={() => handleRemoveRole(role)}
            >
              <X className="bg-secondary" />
            </Button>
          </Badge>
        ))}
      </Container>
    </>
  );
}
