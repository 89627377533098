import { atom } from "recoil";

type Permissions = string[];

const auth0PermissionsAtom = atom<Permissions>({
  key: "auth0permissions",
  default: [],
});

export const isPermissionLoaded = atom<boolean>({
  key: "isPermissionLoaded",
  default: false,
});

export default auth0PermissionsAtom;
